import { IdleOverlay } from 'Components/IdleOverlay';
import React           from 'react';
import styled          from '@emotion/styled';
import { useEffect }   from 'react';
import { observer }    from 'mobx-react';
import { useRouter }   from 'react-router5';
import { useTitle }    from 'react-use';
import { routes }      from 'routes';
import { Button }      from 'rsuite';
import { Footer }      from 'rsuite';
import { Header }      from 'rsuite';
import { Container }   from 'rsuite';

import { DatepickerNav } from 'Components/Diary/DatepickerNav';
import { WeekdayNav }    from 'Components/Diary/WeekdayNav';
import { DayEditor }     from 'Components/DayEditor';
import { useAuthStore }  from 'Stores/AuthStore';
import { useDiaryStore } from 'Stores/DiaryStore';

const ToolbarLayout = styled.div`
  display             : flex;
  justify-content     : space-between;
  align-items         : center;
  background-color    : #0f131a;
  color               : #585a5c;

  -webkit-user-select : none; /* Safari */
  -moz-user-select    : none; /* Firefox */
  -ms-user-select     : none; /* IE10+/Edge */
  user-select         : none; /* Standard */

  .rs-btn-subtle {
    color : #585a5c;
  }

  .rs-btn-active {
    background-color : transparent;
    color            : white;
  }
`;

const Bottom = observer( () => {
    
    const auth   = useAuthStore();
    const router = useRouter();
    
    const logout = async () => {
        await auth.logOut();
        router.navigate( routes.login.name );
    };
    
    return <ToolbarLayout>
        <Button appearance="subtle" onClick={ logout }>(X)</Button>
    </ToolbarLayout>;
    
} );

const Top = observer( () => {
    
    const diary  = useDiaryStore();
    const router = useRouter();
    
    useTitle( diary.selectedDate );
    
    const gotoDate = ( date : string ) => {
        router.navigate( routes.day.name, { date } );
    };
    
    const gotoToday = () => {
        const date = diary.formatDateToDateString( new Date() );
        router.navigate( routes.day.name, { date } );
    };
    
    return <ToolbarLayout>
        <Button appearance="subtle" onClick={ gotoToday }>DevDiary</Button>
        <WeekdayNav onDateChange={ gotoDate }/>
        <DatepickerNav onDateChange={ gotoDate }/>
    </ToolbarLayout>;
    
} );

const FullHeightContainer = styled( Container )`
  height : 100%;
`;

const PaddingToolbars = styled.div`
  padding : 10px;
`;

const Padded = styled.div`
  padding : 20px;
  height  : 100%;
`;

export const DiaryPage = observer( () => {
    
    console.log( 'DiaryPage created.' );
    
    const diary = useDiaryStore();
    
    useEffect( () => {
        diary.loadDay();
    } );
    
    return <IdleOverlay
        onActive={ async () => {
            await diary.loadDay();
        } }>
        <FullHeightContainer>
            <Header>
                <PaddingToolbars>
                    <Top/>
                </PaddingToolbars>
            </Header>
            <FullHeightContainer>
                <Padded>
                    <DayEditor/>
                </Padded>
            </FullHeightContainer>
            <Footer>
                <PaddingToolbars>
                    <Bottom/>
                </PaddingToolbars>
            </Footer>
        </FullHeightContainer>
    </IdleOverlay>
    
} );
