import createRouter5 from 'router5';
import browserPlugin from 'router5-plugin-browser';

const routes = {
    login : { name : 'login', path : '/login' },
    day   : { name : 'day', path : '/day/:date' },
}

const createRouter = () => {
    
    const router = createRouter5( Object.values( routes ), {
        defaultRoute : routes.login.name
    } );
    
    router.usePlugin( browserPlugin({
        useHash : true
    }) );
    
    return router;
}

export { routes, createRouter };

