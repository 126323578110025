import dayjs                  from 'dayjs';
import { clone }              from 'lodash-es';
import React                  from 'react';
import { FC }                 from 'react';
import { useContext }         from 'react';
import { createContext }      from 'react';
import { makeAutoObservable } from 'mobx';
import { runInAction }        from 'mobx';

import { apiService } from 'API/ApiService';
import { Day }        from 'API/Models/Day';

export interface Weekday {
    date : string;
    day : string;
    isActive : boolean
}

class DiaryStore {
    
    isBusy : boolean = true;
    
    dateFormat : string = 'YYYY-MM-DD';
    
    todayDate : string;
    
    selectedDate : string;
    
    currentDay : Day = {
        checkIn  : null,
        checkOut : null,
        date     : '',
        id       : 0,
        notes    : '',
        pause    : null,
        tasks    : null
        
    }
    
    constructor() {
        makeAutoObservable( this );
        const today          = dayjs().format( this.dateFormat )
        this.todayDate       = today;
        this.currentDay.date = today;
        this.selectedDate    = today;
    }
    
    get isToday() {
        return this.todayDate === this.currentDay.date;
    }
    
    get selectedDateStringAsDate() : Date {
        return dayjs( this.selectedDate, this.dateFormat ).toDate();
    }
    
    formatDateToDateString( date : Date ) {
        return dayjs( date ).format( this.dateFormat );
    }
    
    setDate( date : string ) {
        this.selectedDate = date;
    }
    
    async loadDay() {
        this.isBusy = true;
        try {
            const day = await apiService.loadDay( this.selectedDate );
            runInAction( () => {
                this.currentDay = day;
                this.isBusy     = false;
            } );
        } catch ( e ) {
            runInAction( () => {
                this.isBusy = false;
            } );
            throw e;
        }
    }
    
    async saveNote( notes : string ) {
        try {
            const updated = clone<Day>( this.currentDay );
            updated.notes = notes;
            await apiService.saveDay( updated );
            //dont save - this will reload UI
            //this.currentDay.notes = note;
            //const day             = await apiService.saveDay( this.currentDay );
            //runInAction( () => {
            //    this.currentDay = day;
            //} );
        } catch ( e ) {
            
            throw e;
        }
    }
    
    get week() : Weekday[] {
        const start = dayjs( this.todayDate, this.dateFormat ).startOf( 'day' ).subtract( 3, 'day' );
        
        const week = [];
        
        for ( let i = 1; i < 6; i++ ) {
            let d = start.clone().add( i, 'day' );
            week.push( {
                date     : d.format( this.dateFormat ),
                day      : d.format( 'ddd' ).toLowerCase(),
                isActive : d.format( this.dateFormat ) === this.selectedDate
            } );
        }
        
        return week;
    }
    
}

const DiaryStoreContext = createContext<DiaryStore>( new DiaryStore() );

const DiaryStoreProvider : FC<{ store : DiaryStore }> = ( { store, children } ) => {
    return (
        <DiaryStoreContext.Provider value={ store }>{ children }</DiaryStoreContext.Provider>
    );
};

const useDiaryStore = () => {
    return useContext( DiaryStoreContext );
}

export { DiaryStore, DiaryStoreProvider, useDiaryStore };
