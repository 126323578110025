import React          from 'react';
import { observer }   from 'mobx-react';
import { DatePicker } from 'rsuite';
import styled         from '@emotion/styled';

import { SharedNavProps } from 'Components/Diary/SharedNavProps';
import { useDiaryStore }  from 'Stores/DiaryStore';
import { colors }         from 'theme';

interface Props {
}

export const DatepickerNav = observer( ( { onDateChange } : Props & SharedNavProps ) => {
    
    const diary = useDiaryStore();
    
    const handleDateChange = ( d : Date ) => {
        const date = diary.formatDateToDateString( d );
        onDateChange( date );
    };
    
    const weekNavDates = diary.week.map( wd => wd.date );
    
    const isActive = diary.selectedDate !== diary.todayDate && !weekNavDates.includes( diary.selectedDate );
    
    const StyleOverride = styled.div`
      .rs-picker-placement-bottom-start .rs-picker-toggle-caret::before,
      .rs-picker-placement-bottom-end .rs-picker-toggle-caret::before,
      .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
      .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
        color : ${ isActive ? colors.toolbar.active : colors.toolbar.normal };
      }
    `;
    
    return <StyleOverride>
        <DatePicker
            oneTap size="sm"
            appearance="subtle"
            placement="bottomEnd"
            value={ diary.selectedDateStringAsDate }
            cleanable={ false }
            ranges={ [] }
            onChange={ handleDateChange }
        />
    </StyleOverride>;
    
} )
