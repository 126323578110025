import React           from 'react';
import styled          from '@emotion/styled';
import { debounce }    from 'lodash-es';
import { ChangeEvent } from 'react';
import { observer }    from 'mobx-react';
import { toJS }        from 'mobx';
import { useEffect }   from 'react';
import { useRef }      from 'react';
import { useState }    from 'react';
import { Loader }      from 'rsuite';

import { useDiaryStore } from 'Stores/DiaryStore';

const LoaderBoundary = styled.div`
  position : relative;
  height   : 100%;
`;

const Editor = styled.textarea`

  background-color : #0f131a;
  color            : #ffffff;

  width            : 100%;
  height           : 100%;
  margin           : 0;
  padding          : 0;
  border           : 0;
  outline          : 0;
  font-size        : inherit;
  line-height      : inherit;
  display          : block;
  resize           : none;

  .editor::-moz-selection {
    //background-color : white;
    //color            : #161616;
    text-shadow : none;
  }

  .editor::selection {
    //background-color : white;
    //color            : #161616;
    text-shadow : none;
  }

  .editor::placeholder {
    color       : #3e3e3e;
    text-shadow : none;
  }
`;

export const TextEditor = ( { value, onChange } : {
    value : string,
    onChange : ( value : string ) => void
} ) => {
    
    const [ note, setNote ] = useState<string>( value );
    
    useEffect( () => setNote( value ), [ value ] );
    
    const debouncedOnChange = useRef(
        debounce( ( value : string ) => onChange( value ), 500 )
    ).current;
    
    const handleChange = ( e : ChangeEvent<HTMLTextAreaElement> ) => {
        setNote( e.target.value );
        debouncedOnChange( e.target.value );
    };
    
    return <Editor
        placeholder={ 'Your notes here...' } autoFocus={ true }
        value={ note }
        onChange={ handleChange }
        spellCheck={ false }
    />;
};

export const DayEditor = observer( () => {
    
    const diary = useDiaryStore();
    
    const onChange = async ( note : string ) => {
        await diary.saveNote( note );
    };
    
    return <LoaderBoundary>
        <TextEditor
            onChange={ onChange }
            value={ toJS( diary.currentDay ).notes }/>
        
        { diary.isBusy && <Loader backdrop vertical/> }
    </LoaderBoundary>;
} );
