import { useEffect } from 'react';
import React         from 'react';
import { useRoute }  from 'react-router5';
import { Alert }     from 'rsuite';

import { useAuthStore }       from 'Stores/AuthStore';
import { AuthStoreProvider }  from 'Stores/AuthStore';
import { useDiaryStore }      from 'Stores/DiaryStore';
import { DiaryStoreProvider } from 'Stores/DiaryStore';
import { LoginPage }          from 'Pages/LoginPage';
import { DiaryPage }          from 'Pages/DiaryPage';
import { routes }             from 'routes';

const pages = {
    [ routes.login.name ] : <LoginPage/>,
    [ routes.day.name ]   : <DiaryPage/>,
}

const PageSwitch = () => {
    
    const { route } = useRoute();
    const diary     = useDiaryStore();
    
    useEffect( () => {
        const fetchDay = async () => {
            if ( route.name === routes.day.name ) {
                try {
                    diary.setDate( route.params.date );
                    await diary.loadDay();
                } catch ( e ) {
                    Alert.error( 'Could not load day.' );
                }
            }
            
        };
        fetchDay();
    }, [ diary, route ] );
    
    return <>{ pages[ route.name ] }</>;
};

const App = () => {
    
    console.log( 'App created.' );
    
    const diaryStore = useDiaryStore();
    const authStore  = useAuthStore();
    
    return <>
        <AuthStoreProvider store={ authStore }>
            <DiaryStoreProvider store={ diaryStore }>
                <PageSwitch/>
            </DiaryStoreProvider>
        </AuthStoreProvider>
    </>
}

export default App;
