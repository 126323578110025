import styled             from '@emotion/styled';
import { SharedNavProps } from 'Components/Diary/SharedNavProps';
import { observer }       from 'mobx-react';
import React              from 'react';
import { Button }         from 'rsuite';
import { useDiaryStore }  from 'Stores/DiaryStore';

const Week = styled.div`
  display   : flex;
  flex-wrap : nowrap;

  & > button + button {
    margin-left : 0.25rem;
  }

`;

interface Props {
}

export const WeekdayNav = observer( ( { onDateChange } : Props & SharedNavProps ) => {
    
    const diary = useDiaryStore();
    
    return <Week>
        { diary.week.map( weekday =>
            <Button
                onClick={ () => onDateChange( weekday.date ) }
                size="sm"
                active={ weekday.isActive }
                appearance="subtle"
                key={ weekday.date }>
                { weekday.day }
            </Button> ) }
    </Week>
} );
